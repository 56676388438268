import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseInvoiceStatementEdit(config) {
  const purchaseOrderTable = ref(null)
  const inboundReturnboundTable = ref(null)

  // Table Handlers
  const purchaseOrderTableColumns = [
    { key: 'no', label: '序号'},
    { key: 'order_no', label: '采购订单'},
    { key: 'type_id', label: '业务类型'},
    { key: 'payment_method', label: '付款方式'},
    { key: 'purchase_total', label: '订单金额'},
    { key: 'reward_amount', label: '返利抵扣金额（非必填）'},
    { key: 'refund_amount', label: '退款抵扣金额（非必填）'},
    { key: 'actions', label: '操作'},
  ]
  // Table Handlers
  const inboundReturnboundTableColumns = [
    { key: 'no', label: '序号'},
    { key: 'bound_no', label: '单据编号'},
    { key: 'warehouse_name', label: '仓库名称'},
    { key: 'qty', label: '数量'},
    { key: 'amount', label: '金额'},
    { key: 'add_time', label: '创建日期'},
    { key: 'creator', label: '创建人'},
    { key: 'status', label: '状态'},
    { key: 'actions', label: '操作'},
  ]

  /*const searchList = (ctx, callback) => {
    callback(config.selected)
  }*/
  return {
    // searchList,
    purchaseOrderTableColumns,
    inboundReturnboundTableColumns,
    purchaseOrderTable,
    inboundReturnboundTable,

  }
}
