<template>
  <div>
    <b-card
        no-body
        class="mb-0 list-table"
    >
<!--      <div>
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-card>
              <b-row>
&lt;!&ndash;筛选条件&ndash;&gt;
&lt;!&ndash;                <b-col
                    cols="6"
                    md="3"
                >
                  <b-form-group
                      label-cols="3"
                      label-cols-lg="3"
                      label="商品名称:"
                      label-for="name"
                      label-size="sm"
                  >
                    <b-form-input
                        id="name"
                        v-model="productName"
                        class="d-inline-block mr-1"
                        size="sm"
                    />
                  </b-form-group>
                </b-col>&ndash;&gt;

                <b-col
                    cols="12"
                    md="12"
                    class=" demo-inline-spacing d-flex align-items-center justify-content-end"
                >
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="submit"
                  >
                    <span class="align-right">查询</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      type="reset"
                  >
                    <span class="align-right">重置</span>
                  </b-button>
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
                      @click="clearCheckBox()"
                      class="mr-1"
                      size="sm"
                  >
                    <feather-icon
                        icon="Trash2Icon"
                        class="mr-50"
                    />
                    <span class="align-middle">清空勾选</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
      </div>-->
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="bound_id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          v-model="table"
      >
      <!-- 明细 -->
      <template #row-details="row">
        <b-card>
          <div>
            <b-table striped hover :items="row.item.item_list" :fields="fields">
              <template #cell(name)="data">
                {{data.item.name}}
              </template>
              <template #cell(category_name)="data">
                {{data.item.category_name}}
              </template>
              <template #cell(code)="data">
                {{data.item.code}}
              </template>
              <template #cell(one_code)="data">
                {{data.item.one_code}}
              </template>
              <template #cell(product_unit)="data">
                {{getCodeLabel('product_unit', data.item.product_unit)}}
              </template>
              <template #cell(trueqty)="data">
                {{data.item.trueqty}}
              </template>
              <template #cell(invoice_qty)="data">
                {{data.item.invoice_qty}}
              </template>
<!--              <template #cell(new_invoice_qty)="data">
                {{data.item.new_invoice_qty}}
              </template>-->
              <template #cell(product_cost_tax)="data">
                {{data.item.product_cost_tax}}
              </template>
              <template #cell(product_cost)="data">
                {{data.item.product_cost}}
              </template>
              <template #cell(tax_rate)="data">
                {{data.item.tax_rate*100}}%
              </template>
              <template #cell(tax_subtotal)="data">
                {{data.item.tax_subtotal}}
              </template>
              <template #cell(product_cost_subtotal)="data">
                {{data.item.product_cost_subtotal}}
              </template>
              <template #cell(product_cost_tax_subtotal)="data">
                {{data.item.product_cost_tax_subtotal}}
              </template>
            </b-table>
          </div>
        </b-card>
      </template>

        <template v-slot:head(#)="data">
          <b-form-checkbox v-model="allchecked" @change="toggleAll" :indeterminate="indeterminate">
          </b-form-checkbox>
        </template>

        <template v-slot:cell(#)="row">
          <b-form-checkbox @change="checkboxClick(row.item,$event)" v-model="isCheckedObj['index'+row.item.bound_id]">
          </b-form-checkbox>
        </template>

        <!-- Columns -->
        <template #cell(bound_no)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          {{data.item.bound_no}}
        </template>
        <template #cell(warehouse_name)="data">
          {{ data.item.warehouse_name }}
        </template>
        <!-- 1采购入库单   2采购退货单 -->
        <template #cell(qty)="data">
          <span v-if="data.item.type_id == '2'" style="color: #cb2027">{{ data.item.qty }}</span>
          <span v-if="data.item.type_id != '2'">{{ data.item.qty }}</span>
        </template>
        <template #cell(amount)="data">
          <span v-if="data.item.type_id == '2'" style="color: #cb2027">{{ data.item.amount }}</span>
          <span v-if="data.item.type_id != '2'">{{ data.item.amount }}</span>
        </template>
        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>
        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>
        <template #cell(status)="data">
          {{ data.item.status }}
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormCheckbox, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, getCode, getCodeColor, getCodeLabel, getCodeOptions,toDate } from '@core/utils/filter'
import inboundReturnboundList from './inboundReturnboundList'
import purchaseInvoiceStatementListStore from "@/views/apps/purchaseinvoicestatement/purchaseInvoiceStatementListStore";

export default {
  components: {
    BCard,
    BModal,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      indeterminate: false,
      allchecked: false,
      isCheckedObj: {},
      selected: [],

      fields: [
        {
          key: 'name',
          label: '商品名称',
        },
        {
          key: 'category_name',
          label: '类目',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'one_code',
          label: '集团条码',
        },
        {
          key: 'product_unit',
          label: '单位',
        },
        {
          key: 'trueqty',
          label: '实际入/出库数量',
        },
        {
          key: 'invoice_qty',
          label: '已开票数量',
        },
        /*{
          key: 'new_invoice_qty',
          label: '开票数量',
        },*/
        {
          key: 'product_cost_tax',
          label: '含税单价',
        },
        {
          key: 'product_cost',
          label: '不含税单价',
        },
        {
          key: 'tax_rate',
          label: '税率',
        },
        {
          key: 'tax_subtotal',
          label: '税额',
        },
        {
          key: 'product_cost_subtotal',
          label: '不含税金额',
        },
        {
          key: 'product_cost_tax_subtotal',
          label: '含税金额',
        },
      ],
    }
  },
  watch: {
    start:'refetchData',
    limit:'refetchData'
  },
  props:{
    selected_no: {
      type: Array,
      default: () => []
    },
    order_id: {
      type: Number
    },
  },

  methods: {
    getSelected: function () {
      return this.selected
    },
    searchByCondition(){
      this.refetchData()
    },
    resetCondition() {
      this.refetchData()
    },
    // 清空选择框
    clearCheckBox() {
      this.allchecked = false;
      this.indeterminate = false
      this.selected = []
      this.$forceUpdate()
    },
    refetchData(){
      this.$refs.refListTable.refresh()
    },
    // 点击全选按钮
    toggleAll(checked) {
      const arrSelected = [];
      if(this.selected&&this.selected.length>0){
        this.selected.forEach(element => {
          arrSelected.push(element.bound_id)
        });
      }
      this.table.forEach(element => {
        this.isCheckedObj['index' + element.bound_id] = checked;
        let indexOf = arrSelected.indexOf(element.bound_id);
        if(indexOf >-1&&!checked){
          arrSelected.splice(indexOf, 1);
          this.selected.splice(indexOf, 1);
        }else if(indexOf < 0 && checked){
          arrSelected.push(element.bound_id);
          this.selected.push(element);
        }
      });
      this.$forceUpdate()
    },
    // 复选框点击选中与取消
    checkboxClick(item,checked) {
      //全选框
      let allselect = true;
      this.table.forEach(element => {
        if (this.isCheckedObj['index' + element.bound_id] !== true) {
          allselect = false;
        }
      });
      this.allchecked = allselect;
      if(checked){
        this.selected.push(item)
      }else{
        for(let i=0;i<this.selected.length;i++){
          if(this.selected[i].bound_id == item.bound_id){
            this.selected.splice(i, 1);
          }
        }
      }
      this.$forceUpdate()
    },
  },
  directives: {
    Ripple,
  },
  setup(props) {
    // Register module
    if (!store.hasModule('inboundReturnboundList')) store.registerModule('inboundReturnboundList', purchaseInvoiceStatementListStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('inboundReturnboundList')) store.unregisterModule('inboundReturnboundList')
    })

    const {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,
      /*orderType,*/
    } = inboundReturnboundList(
        {order_id:props.order_id,selected_no:props.selected_no}
    )

    return {
      searchList,
      table,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      orderBy,
      isSortDirDesc,
      refListTable,

      /*orderType,*/
      // Filter
      avatarText,
      getCodeLabel,
      getCode,
      getCodeColor,
      getCodeOptions,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
