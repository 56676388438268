import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function purchaseOrderList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#' },
    { key: 'order_no', label: '采购订单' },
    { key: 'type_id', label: '业务类型' },
    { key: 'purchase_total', label: '订单金额' },
    { key: 'supplier_name', label: '供应商' },
    { key: 'company_name', label: '采购主体' },
    { key: 'contract_no', label: '合同编号' },
    { key: 'applier_id', label: '申请人' },
    { key: 'create_time', label: '创建日期' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" a.create_time desc ")
  const isSortDirDesc = ref(false)
  const searchKey = ref('')
  /*const orderType = ref()*/

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    console.log("12312312312")
    store
        .dispatch('purchaseOrderList/listPurchaseOrder', {
          search_key: searchKey.value,
          supplier_id:config.supplier_id,
          company_id:config.company_id,
          selectedPurchaseOrderNo:config.selectedPurchaseOrderNo.join(","),
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals

          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购订单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    /*orderType,*/
  }
}
