import {ref, watch, computed, reactive} from '@vue/composition-api'
import store from '@/store'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function inboundReturnboundList(config) {
  const toast = useToast()
  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: '#', label: '#' },
    { key: 'bound_no', label: '单据编号' },
    { key: 'warehouse_name', label: '仓库名称' },
    { key: 'qty', label: '数量' },
    { key: 'amount', label: '金额' },
    { key: 'add_time', label: '创建日期' },
    { key: 'creator', label: '创建人' },
    { key: 'status', label: '状态' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const orderBy = ref(" ")
  const isSortDirDesc = ref(false)
  /*const orderType = ref()*/

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const searchList = (ctx, callback) => {
    console.log("吱吱吱吱吱吱吱吱吱吱吱吱吱吱吱吱吱吱吱")
    store
        .dispatch('inboundReturnboundList/listInboundReturnbound', {
          order_id:config.order_id,
          selected_no:config.selected_no.join(","),
          start: start.value,
          limit: limit.value,
          order_by: orderBy.value,
          order_desc: isSortDirDesc.value === true ? 'desc':'',
        })
        .then(response => {
          const data = response.data.data
          const list = data.ext.list
          listTotals.value = data.ext.totals

          callback(list)
        })
        .catch((e) => {
          toast({
            component: ToastificationContent,
            props: {
              title: '采购订单列表获取错误',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })

  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    orderBy,
    isSortDirDesc,
    refListTable,
    /*orderType,*/
  }
}
