<template>
  <b-card>
    <b-row>
<!--      <b-col
          cols="12"
          class="mt-50"
          style="margin-bottom: 20px"
      >
        <b-button
            variant="outline-success"
            class="mr-1"
            @click="save(10)"
        >
          提交
        </b-button>
        <b-button
            variant="outline-success"
            class="mr-1"
            @click="save(1)"
        >
          保存
        </b-button>
        <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="()=>{$router.go(-1)}"
        >
          取消
        </b-button>

      </b-col>-->
      <b-col md="12">
        <div class="inner-card-title">采购订单</div>
      </b-col>

      <b-col
          cols="12" md="4">
        <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="供应商"
            label-for="supplier_name"
            label-size="sm"
            class="mb-1 required"
        >
          <b-form-input
              id="supplier_name"
              size="sm"
              readonly
              v-model="purchaseInvoiceStatement.supplier_name"
              @click="showSingleModal('供应商',companyEditFlag)"
              readonly
              placeholder="点击搜索供应商"
          />
        </b-form-group>
      </b-col>

      <b-col
          cols="12" md="4">
        <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="采购主体"
            label-for="company_name"
            label-size="sm"
            class="mb-1 required"
        >
          <b-form-input
            id="company_name"
            size="sm"
            v-model="purchaseInvoiceStatement.company_name"
            @click="showSingleModal('采购主体',companyEditFlag)"
            readonly
            placeholder="点击搜索采购主体"
          />
        </b-form-group>
      </b-col>

      <b-col
          cols="12"
          md="4"
      >
        <b-form-group
            label-cols="4"
            label-cols-lg="4"
            label="合同编号:"
            label-for="contract_no"
            label-size="sm"
        >
          <b-form-input
              id="contract_no"
              v-model="purchaseInvoiceStatement.contract_no"
              class="d-inline-block mr-1"
              size="sm"
              :readonly="true"
          />
        </b-form-group>
      </b-col>
      <b-col
          cols="12"
          md="6"
          v-if="!readonlyFlag"
      >
        <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="添加订单"
            label-for="addPurchaseOrder"
            label-size="sm"
            style="margin-left: -25px"
        >
          <b-button
              id="addPurchaseOrder"
              variant="outline-secondary"
              v-b-modal.modal-select-purchase-order
              @click="addPurchaseOrder()"
          >
            <feather-icon
                icon="PlusIcon"
            />
          </b-button>
          <div>
            <span style="font-size: 1px;color: #cb2027"> 供应商、采购主体保存后不可更改，如需调整请新开应付结算</span>
          </div>
        </b-form-group>
      </b-col>
        <b-table
            ref="purchaseOrderTable"
            class="position-relative"
            :items="purchaseOrderSearchList"
            responsive
            show-empty
            empty-text="未找到记录"
            :fields="purchaseOrderTableColumns"
            primary-key="order_id"
            v-model="purchaseOrderDataList"
        >

          <template #cell(no)="data">
            {{ data.item.no }}
          </template>
          <template #cell(order_no)="data">
            {{ data.item.order_no }}
          </template>
          <template #cell(type_id)="data">
            {{ getCodeLabel('business_type', data.item.type_id) }}
          </template>
          <template #cell(payment_method)="data">
            {{ getCodeLabel('agreement_pay_method', data.item.payment_method) }}
          </template>
          <template #cell(purchase_total)="data">
            {{ data.item.purchase_total }}
          </template>
          <template #cell(reward_amount)="data">
            <b-form-input
                size="sm"
                v-model="data.item.reward_amount"
                style="width: 80px"
                type="number"
                :readonly="readonlyFlag"
                @change="checkAmount(data.item,'reward_amount')"
            />
          </template>
          <template #cell(refund_amount)="data">
            <b-form-input
                size="sm"
                v-model="data.item.refund_amount"
                style="width: 80px"
                type="number"
                :readonly="readonlyFlag"
                @change="checkAmount(data.item,'refund_amount')"
            />
          </template>
          <template #cell(actions)="data">
            <b-dropdown
                variant="link"
                no-caret
            >
              <template #button-content>
                <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                />
              </template>
              <b-dropdown-item :disabled="readonlyFlag" @click="addInboundReturnBound(data.item)" v-b-modal.modal-select-inbound-returnbound>
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50" >关联入库/退货单</span>
              </b-dropdown-item>
              <b-dropdown-item :disabled="readonlyFlag" @click="deletePurchaseOrder(data)">
                <feather-icon icon="FileTextIcon"/>
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      <b-col md="12">
        <div class="inner-card-title">入库退库</div>
      </b-col>
      <b-table
          ref="inboundReturnboundTable"
          class="position-relative"
          :items="inboundReturnBoundSearchList"
          responsive
          show-empty
          empty-text="未找到记录"
          :fields="inboundReturnboundTableColumns"
          primary-key="bound_id"
          v-model="inboundReturnBoundDataList"
      >
        <template #row-details="row">
        <b-card>
          <div>
            <b-table striped hover :items="row.item.item_list" :fields="fields">
              <template #cell(name)="data">
                {{data.item.name}}
              </template>
              <template #cell(category_id)="data">
                {{getCodeLabel('category', data.item.category_id)}}
              </template>
              <template #cell(code)="data">
                {{data.item.code}}
              </template>
              <template #cell(one_code)="data">
                {{data.item.one_code}}
              </template>
              <template #cell(product_unit)="data">
                {{getCodeLabel('product_unit', data.item.product_unit)}}
              </template>
              <template #cell(trueqty)="data">
                {{data.item.trueqty}}
              </template>
              <template #cell(invoice_qty)="data">
                {{data.item.invoice_qty}}
              </template>
              <template #cell(new_invoice_qty)="data">
                <b-form-input
                    size="sm"
                    v-model="data.item.new_invoice_qty"
                    style="width: 80px"
                    type="number"
                    :readonly="readonlyFlag"
                    @change="changeInvoiceQty(data.item)"
                />
              </template>
              <template #cell(product_cost_tax)="data">
                {{data.item.product_cost_tax}}
              </template>
              <template #cell(product_cost)="data">
                {{data.item.product_cost}}
              </template>
              <template #cell(tax_rate)="data">
                {{data.item.tax_rate*100}}%
              </template>
              <template #cell(invoice_tax_subtotal)="data">
                {{data.item.invoice_tax_subtotal}}
              </template>
              <template #cell(invoice_product_cost_subtotal)="data">
                {{data.item.invoice_product_cost_subtotal}}
              </template>
              <template #cell(invoice_product_cost_tax_subtotal)="data">
                {{data.item.invoice_product_cost_tax_subtotal}}
              </template>
            </b-table>
          </div>
        </b-card>
      </template>

        <template #cell(no)="data">
          {{ data.item.no }}
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
        </template>
        <template #cell(bound_no)="data">
          {{ data.item.bound_no }}
        </template>
        <template #cell(warehouse_name)="data">
          {{ data.item.warehouse_name }}
        </template>
        <!-- 1采购入库单   2采购退货单 -->
        <template #cell(qty)="data">
          <span v-if="data.item.type_id == '2'" style="color: #cb2027">{{ data.item.qty }}</span>
          <span v-if="data.item.type_id != '2'">{{ data.item.qty }}</span>
        </template>
        <template #cell(amount)="data">
          <span v-if="data.item.type_id == '2'" style="color: #cb2027">{{ data.item.amount }}</span>
          <span v-if="data.item.type_id != '2'">{{ data.item.amount }}</span>
        </template>
        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>
        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>
        <template #cell(status)="data">
          {{ data.item.status }}
        </template>

        <template #cell(actions)="data">
          <span v-if="!readonlyFlag" class="align-middle ml-50" @click="deleteInboundReturnbound(data)"><feather-icon icon="DeleteIcon"/></span>
        </template>
      </b-table>
            <b-col
                cols="12"
                class="mt-50"
                style="margin-bottom: 20px"
            >
              <b-button
                  v-if="!readonlyFlag"
                  variant="outline-success"
                  class="mr-1"
                  @click="save()"
              >
                保存,并进入下一步
              </b-button>
              <b-button
                  v-if="statement_id&&statement_id!=0"
                  variant="outline-success"
                  class="mr-1"
                  @click="nextStep()"
              >
                下一步
              </b-button>
              <b-button
                  v-if="readonlyFlag"
                  variant="outline-secondary"
                  class="mr-1"
                  @click="()=>{$router.go(-1)}"
              >
                返回
              </b-button>

            </b-col>
    </b-row>

    <b-modal
        id="loadingModal"
        centered
        hide-header
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        ref="loadingModal"
    >
      <p class="my-4">正在处理，请勿关闭浏览器!</p>
    </b-modal>
    <b-modal
        v-if="showPurchaseOrderPage"
        id="modal-select-purchase-order"
        ok-only
        ok-title="确认"
        @ok="onSelectPurchaseOrder"
        cancel-title="取消"
        centered
        size="xl"
        title="选择采购订单"
    >
      <purchase-order-list
          ref="purchaseOrderSelect"
          :selectedPurchaseOrderNo="selectedPurchaseOrderNo"
          :supplier_id="purchaseInvoiceStatement.supplier_id"
          :company_id="purchaseInvoiceStatement.company_id"
      >
      </purchase-order-list>
    </b-modal>
    <b-modal
        v-if="showInboundReturnBoundPage"
        id="modal-select-inbound-returnbound"
        ok-only
        ok-title="确认"
        @ok="onSelectInboundReturnBound"
        cancel-title="取消"
        centered
        size="xl"
        title="选择入库/退货单"
    >
      <inbound-returnbound-list
          ref="inboundReturnBoundSelect"
          :selected_no="selectedInboundReturnBoundNo"
          :order_id="order_id"
      >
      </inbound-returnbound-list>
    </b-modal>
    <b-modal
        id="modal-single"
        ok-only
        ok-title="确认"
        @ok="onSelectSingle"
        cancel-title="取消"
        centered
        size="lg"
        :title="'请选择'+modalName"
        ref="singleModal"
    >
      <company-select
          ref="companySelect" :companyType="companyType"
      >
      </company-select>
    </b-modal>
  </b-card>
</template>


<script>

import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BModal,
  BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {computed, onUnmounted, ref} from '@vue/composition-api'
import store from '@/store'
// Notification
import {useToast} from 'vue-toastification/composition'
import {getCodeOptions, getCode,getCodeLabel, getCodeColor, avatarText, isEmpty, toDate} from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import purchaseInvoiceStatementListStore from "@/views/apps/purchaseinvoicestatement/purchaseInvoiceStatementListStore";
import purchaseInvoiceStatementEdit from "@/views/apps/purchaseinvoicestatement/purchaseInvoiceStatementEdit";
import purchaseOrderList from "@/views/apps/purchaseinvoicestatement/PurchaseOrderList.vue";
import inboundReturnboundList from "@/views/apps/purchaseinvoicestatement/InboundReturnboundList.vue";
import axios from "@/libs/axios";
import CompanySelect from '@/views/apps/company/CompanySelect'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BModal,
    BFormCheckbox,
    vSelect,
    useToast,
    flatPickr,
    AttachmentUpload,
    purchaseOrderList,
    inboundReturnboundList,
    CompanySelect,
  },
  props:{
    /*cash_id :{
      type : Number,
      default:0
    },*/
  },
  data() {
    return {
      purchaseInvoiceStatement: ref({}),
      // supplier:{},
      // supplierArray: [],
      // company:{},
      // companyArray: [],
      purchaseOrderDataList: [],
      inboundReturnBoundDataList: [],
      purchaseOrderSearchList: [],
      inboundReturnBoundSearchList: [],
      showPurchaseOrderPage:false,
      companyType:'',
      modalName:'',
      contractNo:'',
      companyEditFlag:false,
      showInboundReturnBoundPage:false,
      selectedPurchaseOrderNo:[],
      selectedInboundReturnBoundNo:[],
      order_id:null,
      statement_id:null,
      status_one:null,
      readonlyFlag:false,
      invoice_id:null,
      old_company_id:null,
      old_supplier_id:null,
      fields: [
        {
          key: 'name',
          label: '商品名称',
        },
        {
          key: 'category_id',
          label: '类目',
        },
        {
          key: 'code',
          label: '69码',
        },
        {
          key: 'one_code',
          label: '集团条码',
        },
        {
          key: 'product_unit',
          label: '单位',
        },
        {
          key: 'trueqty',
          label: '实际入/出库数量',
        },
        {
          key: 'invoice_qty',
          label: '已开票数量',
        },
        {
          key: 'new_invoice_qty',
          label: '开票数量',
        },
        {
          key: 'product_cost_tax',
          label: '含税单价',
        },
        {
          key: 'product_cost',
          label: '不含税单价',
        },
        {
          key: 'tax_rate',
          label: '税率',
        },
        {
          key: 'invoice_tax_subtotal',
          label: '税额',
        },
        {
          key: 'invoice_product_cost_subtotal',
          label: '不含税金额',
        },
        {
          key: 'invoice_product_cost_tax_subtotal',
          label: '含税金额',
        },
      ],
    }
  },
  methods: {
    onSelectSingle() {
      let data = {}
      switch (this.modalName) {
        case '采购主体': {
          data = this.$refs.companySelect.getSelected()[0]
          this.purchaseInvoiceStatement.company_id = data.company_id
          this.purchaseInvoiceStatement.company_name = data.company_name
          this.$forceUpdate()
          break
        }
        case '供应商': {
          data = this.$refs.companySelect.getSelected()[0]
          this.purchaseInvoiceStatement.supplier_id = data.company_id
          this.purchaseInvoiceStatement.supplier_name = data.company_name
          this.$forceUpdate()
          break
        }
      }
    },
    showSingleModal(modalName,readonlyFlag) {
      if(!readonlyFlag){
        this.modalName = modalName
        if (modalName === '采购主体') {
          this.companyType = 'Group'
        } else if (modalName === '供应商') {
          this.companyType = 'Supplier'
        }
        this.$refs['singleModal'].show()
      }
    },
    addPurchaseOrder(){
      if(this.purchaseInvoiceStatement.company_id && this.purchaseInvoiceStatement.supplier_id){
        this.showPurchaseOrderPage = true;
      }else{
        alert("请先选择供应商和采购主体")
      }
    },
    onSelectPurchaseOrder(){
      let selected = this.$refs.purchaseOrderSelect.getSelected();
      let conNo = this.contractNo;
      if(conNo == ''&&selected.length>0){
        conNo = selected[0]["contract_no"]
      }
      for(let i=0;i<selected.length;i++){
        //验证合同号是否相同
        if(conNo != selected[i]["contract_no"]){
          alert("请选择相同合同的采购订单")
          return;
        }
      }
      let purchaseOrderList = this.purchaseOrderDataList;
      for(let i=0;i<selected.length;i++){
        purchaseOrderList.push(selected[i])
        this.selectedPurchaseOrderNo.push(selected[i]["order_no"])
      }
      let count = 1
      for(let i=0;i<purchaseOrderList.length;i++){
        purchaseOrderList[i]["no"] = count;
        count ++;
      }
      if(this.contractNo == ''&&purchaseOrderList.length>0){
        this.contractNo = purchaseOrderList[0]["contract_no"]
        this.purchaseInvoiceStatement.contract_no = this.contractNo
        this.companyEditFlag = true
      }
      //this.purchaseOrderTable.value.localItems = purchaseOrderList
    },
    addInboundReturnBound(item){
      this.order_id = item.order_id
      this.showInboundReturnBoundPage = true;
    },
    onSelectInboundReturnBound(){
      let selected = this.$refs.inboundReturnBoundSelect.getSelected();
      let inboundReturnBoundList = this.inboundReturnBoundDataList;
      for(let i=0;i<selected.length;i++){
        inboundReturnBoundList.push(selected[i])
        this.selectedInboundReturnBoundNo.push(selected[i]["bound_no"])
      }
      let count = 1
      for(let i=0;i<inboundReturnBoundList.length;i++){
        inboundReturnBoundList[i]["no"] = count;
        count ++;
      }
      //this.inboundReturnboundTable.value.localItems = inboundReturnBoundList
    },
    checkAmount(item,type){

      if (item[type]&&parseFloat(item[type])<0) {
        item[type] = '';
        alert('金额必须是正整数!')
        return
      }
      let reward_amount = isNaN(item.reward_amount)||item.reward_amount==''?0:item.reward_amount;
      let refund_amount = isNaN(item.refund_amount)||item.refund_amount==''?0:item.refund_amount;
      if(this.accAdd(parseFloat(reward_amount),parseFloat(refund_amount))>item.purchase_total){
        item[type] = ''
        alert("返利抵扣金额+退款抵扣金额 不可大于 订单金额")
      }
    },
    changeInvoiceQty(item){
      if(!item.new_invoice_qty){
        item.new_invoice_qty = this.accSub(item.trueqty,item.invoice_qty)
        item.invoice_product_cost_subtotal = this.accMul(item.new_invoice_qty,item.product_cost)
        item.invoice_product_cost_tax_subtotal = this.accMul(item.new_invoice_qty,item.product_cost_tax)
        item.invoice_tax_subtotal = this.accSub(item.invoice_product_cost_tax_subtotal,item.invoice_product_cost_subtotal)
        alert('请填写开票数量!')
        return
      }
      const cRegExp = /^[0-9]\d*$/
      if (!cRegExp.test(item.new_invoice_qty)) {
        item.new_invoice_qty = this.accSub(item.trueqty,item.invoice_qty)
        item.invoice_product_cost_subtotal = this.accMul(item.new_invoice_qty,item.product_cost)
        item.invoice_product_cost_tax_subtotal = this.accMul(item.new_invoice_qty,item.product_cost_tax)
        item.invoice_tax_subtotal = this.accSub(item.invoice_product_cost_tax_subtotal,item.invoice_product_cost_subtotal)
        alert('开票数量必须是正整数!')
        return
      }
      if(item.trueqty<this.accAdd(item.invoice_qty,item.new_invoice_qty)){
        item.new_invoice_qty = this.accSub(item.trueqty,item.invoice_qty)
        item.invoice_product_cost_subtotal = this.accMul(item.new_invoice_qty,item.product_cost)
        item.invoice_product_cost_tax_subtotal = this.accMul(item.new_invoice_qty,item.product_cost_tax)
        item.invoice_tax_subtotal = this.accSub(item.invoice_product_cost_tax_subtotal,item.invoice_product_cost_subtotal)
        alert("已开票数量+新开票数量>实际入/出库数量")
        return
      }
      item.invoice_product_cost_subtotal = this.accMul(item.new_invoice_qty,item.product_cost)
      item.invoice_product_cost_tax_subtotal = this.accMul(item.new_invoice_qty,item.product_cost_tax)
      item.invoice_tax_subtotal = this.accSub(item.invoice_product_cost_tax_subtotal,item.invoice_product_cost_subtotal)
    },
    accAdd (arg1,arg2) {
      let r1,r2,m;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2))
      return (arg1*m+arg2*m)/m;
    },

    accMul (arg1,arg2) {
      let m=0,s1=arg1.toString(),s2=arg2.toString();
      try{m+=s1.split(".")[1].length}catch(e){}
      try{m+=s2.split(".")[1].length}catch(e){}
      return Number(s1.replace(".",""))*Number(s2.replace(".",""))/Math.pow(10,m);
    },
    accSub (arg1,arg2) {
      let r1,r2,m,n;
      try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
      try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
      m=Math.pow(10,Math.max(r1,r2));
      n=(r1>=r2)?r1:r2;
      return ((arg1*m-arg2*m)/m).toFixed(n);
    },
    nextStep(){
      this.$router.push({ name: 'apps-purchase-invoice-statement-edit-next',query: {statement_id: this.statement_id}});
    },
    save(){
      //不可编辑
      if(this.readonlyFlag){
        this.$router.push({ name: 'apps-purchase-invoice-statement-edit-next',query: {statement_id: this.statement_id}});
      }else{
        if(this.purchaseOrderDataList.length==0){
          alert("请先添加订单")
          return
        }
        let no = [];
        let no2 = [];
        for(let i=0;i<this.purchaseOrderDataList.length;i++){
          let relateFlag = false;
          let amount = 0
          for(let j=0;j<this.inboundReturnBoundDataList.length;j++){
            if(this.inboundReturnBoundDataList[j]["order_id"] == this.purchaseOrderDataList[i]["order_id"]){
              relateFlag = true;
              //
              let itemList = this.inboundReturnBoundDataList[j].item_list;
              let typeId = this.inboundReturnBoundDataList[j].type_id;
              for(let k=0;k<itemList.length;k++){
                if(typeId == 1){
                  amount = this.accAdd(amount,itemList[k].invoice_product_cost_tax_subtotal)
                }else{
                  amount = this.accSub(amount,itemList[k].invoice_product_cost_tax_subtotal)
                }
              }
              //
            }
          }
          if(amount<0){
            no2.push(this.purchaseOrderDataList[i]["order_no"])
          }
          if(!relateFlag){
            no.push(this.purchaseOrderDataList[i]["order_no"])
          }
        }
        if(no.length>0){
          alert("采购单："+no.join(",")+"未关联入库退货单")
          return
        }
        /*if(no2.length>0){
          alert("采购单："+no2.join(",")+"入库金额小于出库金额")
          return
        }*/

        let obj = {}
        obj.statement_id = this.statement_id
        obj.supplier_id = this.purchaseInvoiceStatement.supplier_id
        obj.supplier_name = this.purchaseInvoiceStatement.supplier_name
        obj.company_id = this.purchaseInvoiceStatement.company_id
        obj.company_name = this.purchaseInvoiceStatement.company_name
        obj.purchaseOrderJsonStr = JSON.stringify(this.purchaseOrderDataList)
        obj.inboundReturnboundJsonStr = JSON.stringify(this.inboundReturnBoundDataList)
        this.$refs['loadingModal'].show()
        store.dispatch('purchaseInvoiceStatementEdit/saveFirst', obj).then(res => {
          this.$refs['loadingModal'].hide()
          if (res.data.code==0){
            this.statement_id = res.data.data.statement_id
            if(this.purchaseInvoiceStatement.company_id == this.old_company_id &&this.purchaseInvoiceStatement.supplier_id == this.old_supplier_id){
              this.$router.push({ name: 'apps-purchase-invoice-statement-edit-next',query: {
                  statement_id: res.data.data.statement_id,
                  invoice_id: this.invoice_id,
                }})
            }else{
              this.$router.push({ name: 'apps-purchase-invoice-statement-edit-next',query: {statement_id: res.data.data.statement_id,}});
            }
          }else {
            alert(res.data.data);
          }
        })
      }
    },
    deletePurchaseOrder(row){
      let inboundReturnBoundDataList = this.inboundReturnBoundDataList;
      for(let i=0;i<inboundReturnBoundDataList.length;i++){
        if(inboundReturnBoundDataList[i]["order_id"] == row.item.order_id){
          alert("请先删除已添加的入库退库单")
          return;
        }
      }
      if(confirm("确认删除吗？")){
        for(let i=0;i<this.selectedPurchaseOrderNo.length;i++){
          if(this.selectedPurchaseOrderNo[i] == row.item.order_no){
            this.selectedPurchaseOrderNo.splice(i, 1);
          }
        }
        let index = row.index;
        this.purchaseOrderTable.value.splice(index, 1);
        for(let i=0;i<this.purchaseOrderTable.value.length;i++){
          this.purchaseOrderTable.value[i].no = (i+1)
        }
        if(this.selectedPurchaseOrderNo.length == 0){
          this.contractNo = '';
          this.purchaseInvoiceStatement.contract_no = null
          this.companyEditFlag = false
        }
        this.$forceUpdate()
      }
    },
    deleteInboundReturnbound(row){
      for(let i=0;i<this.selectedInboundReturnBoundNo.length;i++){
        if(this.selectedInboundReturnBoundNo[i] == row.item.bound_no){
          this.selectedInboundReturnBoundNo.splice(i, 1);
        }
      }
      let index = row.index;
      this.inboundReturnboundTable.value.splice(index, 1);
      for(let i=0;i<this.inboundReturnboundTable.value.length;i++){
        this.inboundReturnboundTable.value[i].no = (i+1)
      }
      this.$forceUpdate()
    },
    /*showSingleModal(type) {
      if (type === '店铺名称') {
        this.$refs['storeModal'].show()
      }
    },
    onSelectStore() {
      let returnData = {}
      if (this.$refs.storeSelectReceive.getSelected().length > 0) {
        returnData = this.$refs.storeSelectReceive.getSelected()[0]

        this.storeCashOut.store_id = returnData.store_id
        this.storeCashOut.store_name = returnData.store_name
        this.storeCashOut.companyid = returnData.companyid
        this.storeCashOut.companyname = returnData.companyname
        this.storeCashOut.company_id = returnData.company_id
        this.storeCashOut.company_name = returnData.company_name
        this.$forceUpdate()
      }
    },*/
  },
  setup(props) {
    // Register module
    if (!store.hasModule('purchaseInvoiceStatementEdit')) store.registerModule('purchaseInvoiceStatementEdit', purchaseInvoiceStatementListStore)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseInvoiceStatementEdit')) store.unregisterModule('purchaseInvoiceStatementEdit')
    })
    const toast = useToast()
    const onUploaded = function (id, attachment, result) {
      this.storeCashOut[id] = result
    }

    const changeSupplier = function (event) {
      this.purchaseInvoiceStatement.supplier_id = event.id
      this.purchaseInvoiceStatement.supplier_name = event.title
    }

    const changeCompany = function (event) {
      this.purchaseInvoiceStatement.company_id = event.id
      this.purchaseInvoiceStatement.company_name = event.title
    }

    const edit = function (company_id,company_name,supplier_id,supplier_name) {
      store.dispatch('purchaseInvoiceStatementEdit/initEditPage', {id:this.statement_id}).then(res => {
        this.purchaseInvoiceStatement = res.data.data
        if(company_id){
          this.purchaseInvoiceStatement.company_id = company_id
          this.purchaseInvoiceStatement.company_name = company_name
          this.purchaseInvoiceStatement.supplier_id = supplier_id
          this.purchaseInvoiceStatement.supplier_name = supplier_name
        }
        this.status_one = this.purchaseInvoiceStatement.status_one;
        if(res.data.data.status_one&&this.status_one != 1 &&this.status_one != 6 &&this.status_one != 7){
          this.readonlyFlag = true
        }else{
          this.readonlyFlag = false
        }
        if(this.statement_id != 0){
          this.contractNo = this.purchaseInvoiceStatement.contract_no
          //采购单
          let purOrdList = res.data.data.ext.purchaseOrder
          let purchaseOrderList = this.purchaseOrderDataList;
          for(let i=0;i<purOrdList.length;i++){
            purchaseOrderList.push(purOrdList[i])
            this.selectedPurchaseOrderNo.push(purOrdList[i].order_no)
          }
          let count = 1
          for(let i=0;i<purchaseOrderList.length;i++){
            purchaseOrderList[i]["no"] = count;
            count ++;
          }
          this.purchaseOrderTable.value.localItems = purchaseOrderList
          //入库出单
          let inReList = res.data.data.ext.inboundReturnbound
          let inboundReturnBoundList = this.inboundReturnBoundDataList;
          for(let i=0;i<inReList.length;i++){
            inboundReturnBoundList.push(inReList[i])
            this.selectedInboundReturnBoundNo.push(inReList[i].bound_no)
          }
          count = 1
          for(let i=0;i<inboundReturnBoundList.length;i++){
            inboundReturnBoundList[i]["no"] = count;
            count ++;
          }
          this.inboundReturnboundTable.value.localItems = inboundReturnBoundList
          this.$forceUpdate()
        }
      })
    }
    const {
      // searchList,
      purchaseOrderTableColumns,
      inboundReturnboundTableColumns,
      purchaseOrderTable,
      inboundReturnboundTable,


    } = purchaseInvoiceStatementEdit(
        {}
    )

    return {
      // searchList,
      purchaseOrderTableColumns,
      inboundReturnboundTableColumns,
      purchaseOrderTable,
      inboundReturnboundTable,

      toast,
      edit,
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      toDate,
      getCodeColor,
      onUploaded,
      changeSupplier,
      changeCompany,

    }
  },
  created() {
    this.statement_id = this.$route.query.id || 0;
    let company_id = this.$route.query.company_id || null;
    let company_name = this.$route.query.company_name || null;
    let supplier_id = this.$route.query.supplier_id || null;
    let supplier_name = this.$route.query.supplier_name || null;
    let invoice_id = this.$route.query.invoice_id || null;
    if(this.statement_id != 0){
      this.companyEditFlag = true
      this.companyEditFlag = true
    }else{
      this.companyEditFlag = false
      this.companyEditFlag = false
    }
    if(company_id != null){
      this.old_company_id = company_id
    }
    if(supplier_id != null){
      this.old_supplier_id = supplier_id
    }
    if(invoice_id != null){
      this.invoice_id = invoice_id
    }
    this.edit(company_id,company_name,supplier_id,supplier_name);
  },
}
</script>
