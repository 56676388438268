import axios from '@axios'

export default {
  namespaced: true,
  state: {
    condition:{
      status:'1',
    },
  },
  getters: {
    getCondition(state){
      return state.condition
    }
  },
  mutations: {
    updateCondition(state,condition){
      state.condition = condition
    }
  },
  actions: {
    search(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/search', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listPurchaseOrder(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/listPurchaseOrder', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listInboundReturnbound(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/listInboundReturnbound', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    initEditPage(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/initEditPage', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    saveFirst(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/purchaseinvoicestatement/saveFirst', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changeStatus(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/changeStatus', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    printData(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/purchaseinvoicestatement/printData', {params: params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
